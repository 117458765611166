import React, {useEffect, useState} from 'react';
import {objToStr, sendFormEvents} from '../../helpers/utils';

const FormFilling = ({ params, fields, isFormSent }) => {
  const [data, setData] = useState(fields);

  useEffect(() => {
    const interval = setInterval(async () => {
      if ((objToStr(data) !== objToStr(fields)) && !isFormSent) {
        setData(fields);
        delete fields.policy;
        delete fields.file;
        await sendFormEvents({ ...params, values: fields })
      }
    }, 3000);
    return () => {
      clearInterval(interval)
    }
  });

  return null
}

export default FormFilling;

