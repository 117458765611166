import {
  ModalUploadButton, VacancyFormWrapper
} from "./VacancyFom.style";
import React, {useState} from "react";
import {Button, Checkbox, Form, Input, Upload, message} from "antd";
import {CORRECT_FILE_EXTS, FORM_EVENT_TYPE, FORM_TYPES, rules} from "../../../helpers/common"
import {v4 as uuidv4} from 'uuid';
import {sendFormEvents} from '../../../helpers/utils';
import FormFilling from '../../Utils/FormFilling';
import CustomEmailSelect from "../../Utils/CustomEmailSelect";
import CustomLoader from "../../Utils/CustomLoader";

let uuid = uuidv4();

const VacancyForm = ({
  title = null,
  text = null,
  messageFieldCaption = null,
  allLegalLinks = false,
  email = null,
  userName = null,
  withoutBorder = false,
  customSendMail = null,
  vacancyId = null,
  slug = null,
  maxFileCount = 1,
  isSendingEvent = true,
  buttonText = 'Задать вопрос',
  summaryText = 'Прикрепить резюме',
  isShowLoader = false
}) => {
  const [isFirstChange, setIsFirstChange] = useState(true);
  const [formModal] = Form.useForm();
  const [fields, setFields] = useState(formModal.getFieldsValue());
  const [isFormSent, setIsFormSent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const form_type = slug ? slug : (vacancyId ? FORM_TYPES.VACANCY_QUESTION : FORM_TYPES.SUBSCRIPTION_QUESTION);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const beforeUpload = (file) => {
    const isCFT = CORRECT_FILE_EXTS.includes(file.type);
    if (!isCFT) {
      message.error(`Данный формат не поддерживается`);
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 7;
    if (!isLt2M) {
      message.error('Размер файла не должен превышать 7 Мб!');
      return Upload.LIST_IGNORE;
    }
    if (!isCFT || !isLt2M) {
      file.status = 'error';
      file.name = 'Ошибка';
    }
  }

  const props = {
    maxCount: maxFileCount,
    onChange: ({ fileList, file }) => {
      if (file.status !== 'uploading') {
        // console.log();
      }
      if (file.status === 'error') {
        // alert(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleSendQuestion = async () => {
    if (customSendMail) {
      setLoading(true)
      const values = formModal.getFieldsValue()
      const isSuccess = await customSendMail(!isSendingEvent ? values : { ...values, form_filling_id: uuid });
      if (isSuccess) {
        setIsFormSent(true);
        formModal.resetFields();
        uuid = uuidv4();
        setFields(formModal.getFieldsValue());
      }
      setLoading(false)
    }
  }

  const handleFormEvents = async () => {
    if (isSendingEvent) {
      setIsFormSent(false);
      let values = formModal.getFieldsValue();
      setFields(values);
      if (isFirstChange) {
        setIsFirstChange(false);
        delete values.file;
        delete values.policy;
        await sendFormEvents({
          uuid: uuid,
          form_type: form_type,
          event_type: FORM_EVENT_TYPE.FILLING_FORM,
          values: values,
          vacancy_id: vacancyId
        })
      }
    }

  }

  const normFile = (e) => {
    //console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const normOriginFileObj = ({file}) => {
    return file.originFileObj;
  };

  const borderColor = withoutBorder ? { borderColor: 'white' } : null;

  return (
    <>
      {isSendingEvent && (
        <FormFilling fields={fields} isFormSent={isFormSent} params={{
          uuid: uuid,
          form_type: form_type,
          event_type: FORM_EVENT_TYPE.FILLING_FORM,
          vacancy_id: vacancyId
        }} />
      )}

      <VacancyFormWrapper withoutBorder={withoutBorder} className="vacancy__question-wp">
        <h2>{ title ? title : 'Задать вопрос по отрасли и функции' }</h2>
        <div className="p2">{ text ? text : 'Прикрепите ваше резюме, чтобы мы лучше подготовились с ответом' }</div>
        <Form form={formModal}
              layout="vertical"
              className="vacancy__form"
              onFinish={handleSendQuestion}
              onChange={handleFormEvents}>
          <div className="vacancy__form-inputs">
            <div className="vacancy__form-left">
              <Form.Item label="Ваш e-mail"
                         rules={[{ type: 'email', required: true }]}
                         name="email"
                         className="input input-block"
                         initialValue={email}
              >
                <CustomEmailSelect name="email"
                                   containerId="vacancy-form"
                                   className="vacancy-auto-complete vacancy-auto-complete-form"
                                   isCustomPopupContainer={true}
                                   value={formModal && formModal.getFieldValue('email') ? formModal.getFieldValue('email') || undefined : undefined }
                                   style={{ minWidth: 'auto', ...borderColor }}
                                   onChange={(value) => {
                                     if (formModal) {
                                       formModal.setFieldsValue({ email: value });
                                     }
                                   }}
                                   placeholder="Введите данные" />
              </Form.Item>

              <Form.Item label="ФИО"
                         rules={rules}
                         name="name"
                         className="input input-block"
                         initialValue={userName}
              >
                <Input placeholder="Введите данные" />
              </Form.Item>
            </div>
            <div className="vacancy__form-right">
              <Form.Item label={ messageFieldCaption || 'Вопрос по отрасли и функции' }
                         rules={rules}
                         name="text"
                         className="input input-block"
              >
                <Input.TextArea rows={8} className="question-vacancy-form" placeholder="Введите данные" />
              </Form.Item>
            </div>
          </div>
          <div className="vacancy__form-bottom">
            <div className="vacancy__form-bottom-left">
              <Form.Item name={maxFileCount > 1 ?  'files' : 'file'}
                         getValueFromEvent={maxFileCount > 1 ?  normFile : normOriginFileObj}
                         //getValueFromEvent={ ({ file }) => file.originFileObj}
              >
                <Upload {...props}
                        accept={CORRECT_FILE_EXTS.join(',')}
                        beforeUpload={beforeUpload}
                        customRequest={dummyRequest}>
                  <ModalUploadButton>
                    <img src={'/images/home/upload-icon.svg'} width={28} height={28} />
                    {summaryText}
                  </ModalUploadButton>
                </Upload>
              </Form.Item>
            </div>

            <div className="vacancy__form-bottom-right">
              <Form.Item rules={[{
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
               }]}
               className="checkbox"
               valuePropName="checked"
               name="policy">
                <Checkbox>
                  {!allLegalLinks ? (
                    <p className="accept-policy">Я даю согласие на <a className="a2" href="/uploads/soglasie_na_obrabotku_personalnyh_dannyh.pdf" target="_blank">обработку персональных данных</a></p>
                  ) : (
                    <p className="accept-policy">Я даю согласие на <a className="a2" href="/uploads/soglasie_na_obrabotku_personalnyh_dannyh.pdf" target="_blank">обработку персональных данных</a>, <a className="a2" href="/uploads/soglasie_na_poluchenie_novostnoj_i_reklamnoj_rassylki.pdf" target="_blank">получение рекламной рассылки</a> и соглашаюсь с <a className="a2" href="/uploads/polzovatelskoe_soglashenie.pdf" target="_blank">условиями пользовательского соглашения</a></p>
                  )}
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="btn" style={{ marginTop: 0 }}>
                  <CustomLoader isActive={isShowLoader && isLoading}/>
                  <span style={{ display: !(isShowLoader && isLoading) ? 'flex' : 'none' }}>
                    {buttonText}
                    <img src="/images/home/arrow-white-icon.svg" width={17}/>
                  </span>
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </VacancyFormWrapper>
    </>
  )
}

export default VacancyForm;