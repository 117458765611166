import React, {useEffect, useRef, useState} from 'react';
import {Select} from "antd";
import {useDebounce} from "./useDebounce";
import api from "../../api";

import { AutoComplete } from 'antd';

const { Option } = AutoComplete;

export default function CustomEmailSelect({ placeholder = 'Выберите из списка',
                                            className = '', style = {}, containerId = '',
                                            isCustomPopupContainer = false,
                                            allowClear = false,
                                            fromManageLK = false,
                                            name = 'email', onChange = null, onBlur = null, value }) {
  const [searchEmail, setSearchEmail] = useState(value || undefined);
  const [emails, setEmails] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    if (fromManageLK && ref?.current) {
      ref.current.focus();
    }
  }, [fromManageLK, ref])


  const onSearch = (searchText) => {
    setSearchEmail(searchText)
  };

  const debouncedUserEmail = useDebounce(searchEmail, 450);

  useEffect(() => {
    getEmailTooltip(debouncedUserEmail)
  }, [debouncedUserEmail])

  const getEmailTooltip = (value) => {
    if (!!value) {
      return api.get('/suggestions/email', {
        "query": value
      }, null, false)
        .then( data => {
          if (data?.data?.length) {
            if (data.data.includes(value)) {
              setEmails(data.data)
            } else {
              setEmails(data.data)
              //setEmails(value ? [value, ...data.data] : data.data);
            }
          } else {
            //setEmails(value ? [value] : [])
            setEmails([])
          }
          return true;
        })
        .catch( error => {
          return false;
        });
    } else {
      setEmails( []);
    }
  }

  const onSelect = (data) => {
    onChange(data)
    setSearchEmail(data)
  };

  return (
    <>
      <AutoComplete
        ref={ref}
        allowClear={allowClear}
        placeholder={placeholder}
        style={style}
        containerId={containerId}
        name={name}
        className={`custom-email-select ${className}`}
        value={searchEmail}
        onSelect={onSelect}
        onBlur={() => {
          if (onBlur) {
            onBlur(searchEmail)
          } else {
            onChange(searchEmail)
          }
        }}
        onSearch={onSearch}
        onChange={(val) => {
          setSearchEmail(val)
        }}
      >
        {
          emails?.map( (email, i) => {
            return (
              <Option
                key={email}
                value={email}
                className={`simple-select__item custom-email-select-option`}
              >
                { email }
              </Option>
            )
          })
        }
      </AutoComplete>
    </>
  );
}